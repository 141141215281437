import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme/theme";
import AccentTestLanding from "./components/AccentTestLanding";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AccentTestLanding />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

import React from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Card,
  CardContent,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // 正确导入 ExpandMoreIcon
import { Helmet } from "react-helmet";

const AccentTestLanding = () => {
  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Accent Test | Evaluate Your Accent and Speaking Skills</title>
        <meta
          name="description"
          content="Take our professional accent test to evaluate your pronunciation and speaking abilities. Get detailed feedback and improve your English accent today."
        />
        <meta
          name="keywords"
          content="accent test, pronunciation test, speaking evaluation, English accent assessment, accent improvement"
        />
        <link rel="canonical" href="https://yourwebsite.com/accent-test" />
        <meta
          property="og:title"
          content="Professional Accent Test | Speaking Evaluation"
        />
        <meta
          property="og:description"
          content="Evaluate your accent and improve your pronunciation with our comprehensive accent test."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourwebsite.com/accent-test" />
      </Helmet>

      {/* Hero Section */}
      <Box
        component="section"
        sx={{ py: 8, bgcolor: "primary.main", color: "white" }}
      >
        <Container>
          <Typography variant="h1" component="h1" gutterBottom>
            Professional Accent Test
          </Typography>
          <Typography variant="h2" component="h2" gutterBottom>
            Evaluate Your Accent and Improve Your Pronunciation
          </Typography>
          <Button variant="contained" color="success" size="large">
            Start Free Accent Test
          </Button>
        </Container>
      </Box>

      {/* Benefits Section */}
      <Box component="section" sx={{ py: 6 }}>
        <Container>
          <Typography variant="h3" component="h3" gutterBottom>
            Why Take Our Accent Test?
          </Typography>
          <Grid container spacing={3}>
            {benefits.map((benefit, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" component="h4" gutterBottom>
                      {benefit.title}
                    </Typography>
                    <Typography>{benefit.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* How It Works */}
      <Box component="section" sx={{ py: 6, bgcolor: "grey.100" }}>
        <Container>
          <Typography variant="h3" component="h3" gutterBottom>
            How Our Accent Test Works
          </Typography>
          <Grid container spacing={3}>
            {steps.map((step, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Box>
                  <Typography variant="h5" component="h5">
                    Step {index + 1}: {step.title}
                  </Typography>
                  <Typography>{step.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* FAQ Section */}
      <Box component="section" sx={{ py: 6 }}>
        <Container>
          <Typography variant="h3" component="h3" gutterBottom>
            Frequently Asked Questions
          </Typography>
          {faqs.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>

      {/* CTA Section */}
      <Box
        component="section"
        sx={{ py: 6, bgcolor: "primary.main", color: "white" }}
      >
        <Container>
          <Typography variant="h3" component="h3" gutterBottom>
            Ready to Improve Your Accent?
          </Typography>
          <Button variant="contained" color="success" size="large">
            Take Accent Test Now
          </Button>
        </Container>
      </Box>

      <Box
        component="footer"
        sx={{ py: 6, bgcolor: "#1a237e", color: "white" }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                About Us
              </Typography>
              <Typography>
                We are dedicated to helping people improve their accent and
                pronunciation through advanced testing and feedback systems.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <Typography component="div">
                <Box component="ul" sx={{ listStyle: "none", p: 0 }}>
                  <li>Home</li>
                  <li>About</li>
                  <li>Services</li>
                  <li>Contact</li>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography>
                Email: info@accenttest.org
                <br />
                Phone: +1 (555) 123-4567
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography>
              © {new Date().getFullYear()} Accent Test. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const benefits = [
  {
    title: "Accurate Assessment",
    description:
      "Get precise evaluation of your accent and pronunciation patterns",
  },
  {
    title: "Detailed Feedback",
    description: "Receive comprehensive feedback on your speaking abilities",
  },
  {
    title: "Improvement Plan",
    description: "Get personalized recommendations for accent improvement",
  },
];

const steps = [
  {
    title: "Register",
    description: "Create your free account",
  },
  {
    title: "Record",
    description: "Record your voice sample",
  },
  {
    title: "Analysis",
    description: "Get instant accent analysis",
  },
  {
    title: "Results",
    description: "Review detailed feedback",
  },
];

const faqs = [
  {
    question: "What is an accent test?",
    answer:
      "An accent test is a comprehensive evaluation of your pronunciation, intonation, and speaking patterns. It helps identify areas where your accent differs from standard pronunciation and provides guidance for improvement.",
  },
  {
    question: "How long does the test take?",
    answer:
      "The basic accent test typically takes about 10-15 minutes to complete. This includes recording your voice sample and receiving initial feedback. Detailed analysis may take up to 24 hours.",
  },
  {
    question: "Is this test suitable for all English levels?",
    answer:
      "Yes, our accent test is designed for speakers of all proficiency levels, from beginners to advanced learners. The test adapts to your level and provides appropriate feedback.",
  },
  {
    question: "How accurate is the accent evaluation?",
    answer:
      "Our accent evaluation system uses advanced AI technology combined with expert human review to provide highly accurate results. We maintain an accuracy rate of over 95% in accent assessment.",
  },
  {
    question: "What kind of feedback will I receive?",
    answer:
      "You'll receive detailed feedback including: pronunciation accuracy scores, specific sound improvement suggestions, intonation patterns analysis, and personalized recommendations for improvement.",
  },
  {
    question: "Can I retake the test?",
    answer:
      "Yes, you can retake the test as many times as you want. We recommend retaking the test every few months to track your progress and improvement.",
  },
  {
    question: "Do I need any special equipment?",
    answer:
      "You only need a device with a microphone (computer, smartphone, or tablet) and a stable internet connection. No special equipment is required.",
  },
  {
    question: "How much does it cost?",
    answer:
      "We offer a free basic test, and premium evaluations start at $29.99. The premium version includes detailed feedback and a personalized improvement plan.",
  },
];

export default AccentTestLanding;
